import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import pagesRoutes from 'pages/routes';
// import blocksRoutes from 'blocks/routes';
// import demosRoutes from 'demos/routes';
import Sitemap from 'components/Sitemap';

const getBlogPriority = (path) => {
  if (path === '/blog') {
    return '0.9';  // Main blog index
  }
  if (path.includes('/blog/category')) {
    return '0.8';  // Category pages
  }
  // Individual posts - could vary by age/importance
  return '0.7';
};

export const getSitemapRoutes = () => {
  return pagesRoutes.map(route => {
    // Base configuration
    let priority = '0.8';
    let changefreq = 'daily';  // Since these are betting pages, daily updates make sense

    // Customize based on route type
    if (route.path === '/') {
      priority = '1.0';  // Homepage gets highest priority
    } else if (route.path === '/sign-up') {
      priority = '0.9';
      changefreq = 'weekly';  // Sign-up page changes less frequently
    } else if (route.path.includes('/props')) {
      priority = '0.7';  // Props pages slightly lower priority
    }

    // Sport-specific priorities (you might want to adjust these based on season)
    const sportPriorities = {
      'nfl': '0.9',
      'ncaaf': '0.85',
      'nhl': '0.8',
      'mlb': '0.8',
      'mens-cbb': '0.85'
    };

    // Set sport-specific priority if applicable
    for (const [sport, sportPriority] of Object.entries(sportPriorities)) {
      if (route.path.includes(sport)) {
        priority = sportPriority;
        break;
      }
    }

    if (route.path.includes('/blog')) {
      priority = getBlogPriority(route.path);
      changefreq = route.path === '/blog' ? 'daily' : 'weekly';
    }

    return {
      path: route.path,
      priority,
      changefreq
    };
  });
};

const Routes = () => {
  return (
    <ReactRoutes>
      {pagesRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      {/* {blocksRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))} */}
      {/* {demosRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))} */}
      <Route path="*" element={<Navigate replace to="/not-found-cover" />} />
      <Route path="/sitemap" element={<Sitemap />} />
    </ReactRoutes>
  );
};

export default Routes;
