import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Page>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Page>
    </HelmetProvider>
  );
};

export default App;
