import { Helmet } from 'react-helmet-async';
import { useEffect, useRef } from 'react';

const SEO = ({ title, description, keywords }) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (window.location.hostname === 'localhost' && isFirstRender.current) {
      console.log('SEO Mount:', { 
        page: window.location.pathname,
        title, 
        description, 
        keywords 
      });
      isFirstRender.current = false;
    }
  }, [title, description, keywords]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="seo-test" content="plusevbets-seo" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;