import React, { useEffect, useState } from 'react';

const Sitemap = () => {
  const [xml, setXml] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/sitemap.xml')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => {
        setXml(text);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading sitemap...</div>;
  }

  if (error) {
    return (
      <div>
        <h2>Error loading sitemap:</h2>
        <pre style={{ color: 'red' }}>{error}</pre>
        <button onClick={() => window.location.reload()}>
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div>
      <h2>Sitemap Content:</h2>
      <pre style={{ 
        whiteSpace: 'pre-wrap', 
        background: '#f5f5f5', 
        padding: '1rem',
        borderRadius: '4px',
        maxHeight: '600px',
        overflow: 'auto'
      }}>
        {xml}
      </pre>
    </div>
  );
};

export default Sitemap; 